import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { Disclosure } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'

import { useTranslation } from 'react-i18next';

/* Local imports */
import '../assets/styles/header.css';
import logo from '../assets/images/logo.png'

import sk from '../assets/images/countries/sk.svg';
import en from '../assets/images/countries/gb.svg';
import de from '../assets/images/countries/de.svg';

  
function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function Header() {
    const { t, i18n } = useTranslation();

    const handleLanguageChange = (lang) => {
        i18n.changeLanguage(lang);

        const updatedNavigation = [
            { name: t('home'), refto: '/', current: false },
            // { name: t('about'), refto: '/about', current: false },
            { name: t('products'), refto: '/products', current: false },
            { name: t('design') + ' / ' + t('constructions'), refto: '/design', current: false },
            { name: t('contact'), refto: '/contact', current: false },
          ];
        setNavigation(updatedNavigation);
    };

    const openContact = (() => {
        const href = window.location.href.substring(
            window.location.href.lastIndexOf('#') + 1
        );
        const element = document.getElementById(href);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    });

    const [navigation, setNavigation] = useState([
        { name: t('home'), refto: '/', current: false },
        // { name: t('about'), refto: '/about', current: false },
        { name: t('products'), refto: '/products', current: false },
        { name: t('design') + ' / ' + t('constructions'), refto: '/design', current: false },
        { name: t('contact'), refto: '/contact', current: false },
      ])

    // const setActiveMenuItem = (activeItem) => {
    //     setNavigation((prevNavigation) =>
    //         prevNavigation.map((item) =>
    //             item === activeItem ? { ...item, current: true } : { ...item, current: false }
    //         )
    //     );
    // }

    useEffect(() => {
        // Listen to the languageChanged event to update the navigation whenever the language changes.
        i18n.on('languageChanged', () => {
            const updatedNavigation = [
                { name: t('home'), refto: '/', current: false },
                // { name: t('about'), refto: '/about', current: false },
                { name: t('products'), refto: '/products', current: false },
                { name: t('design') + ' / ' + t('constructions'), refto: '/design', current: false },
                { name: t('contact'), refto: '/contact', current: false },
            ];
            setNavigation(updatedNavigation);
        });
    
        // Clean up the event listener when the component unmounts.
        return () => {
            i18n.off('languageChanged');
        };
    // eslint-disable-next-line
    }, [i18n]);

    return (
        <>
            <Disclosure as='nav' className='bg-gray-200 header-nav-menu'>
            {({ open }) => (
                <>
                <div className='mx-auto max-w-7xl px-2 sm:px-6 lg:px-8'>
                    <div className='relative flex h-16 items-center justify-between'>
                    <div className='absolute inset-y-0 left-0 flex items-center sm:hidden'>
                        {/* Mobile menu button*/}
                        <Disclosure.Button className='inline-flex items-center justify-center rounded-md p-2 text-gray-700 hover:bg-gray-700 hover:text-white '>
                        <span className='sr-only'>Menu</span>
                        {open ? (
                            <XMarkIcon className='block h-6 w-6' aria-hidden='true' />
                        ) : (
                            <Bars3Icon className='block h-6 w-6' aria-hidden='true' />
                        )}
                        </Disclosure.Button>
                    </div>
                    <div className='flex flex-1 items-center justify-center sm:items-stretch sm:justify-start'>
                        <div className='flex flex-shrink-0 items-center'>
                        <Link to={'/'}>
                        <img
                            className='block h-8 w-auto lg:hidden'
                            src={logo}
                            alt='VATMANN'
                        />
                        <img
                            className='hidden h-10 w-auto lg:block'
                            src={logo}
                            alt='VATMANN'
                        />
                        </Link>
                        </div>
                        <div className='hidden sm:ml-6 sm:block'>
                        <div className='flex space-x-4'>
                            {navigation.map((item) => (
                            <Link
                                key={item.name}
                                to={item.refto}
                                className={classNames(
                                item.current ? 'bg-gray-600 text-white nav-menu-text' : 'text-gray-300 hover:bg-gray-700 hover:text-white nav-menu-text',
                                'rounded-md px-3 py-3 text-sm font-medium'
                                )}
                                aria-current={item.current ? 'page' : undefined}
                                {...(item.name === 'Kontakt' && {onClick: openContact})}
                                // onClick={() => setActiveMenuItem(item)}
                            >
                                {item.name}
                            </Link>
                            ))}
                        </div>
                        </div>
                        <div className='hidden sm:ml-6 sm:block lg:flex lg:flex-1 lg:justify-end'>
                        <button onClick={() => handleLanguageChange('sk')} className='btn btn-sm'>SK<img src={sk} alt='SK'/></button>
                        <button onClick={() => handleLanguageChange('en')} className='btn btn-sm'>EN<img src={en} alt='EN'/></button>
                        <button onClick={() => handleLanguageChange('de')} className='btn btn-sm'>DE<img src={de} alt='DE'/></button>
                        </div>
                    </div>
                    </div>
                </div>

                <Disclosure.Panel className='sm:hidden'>
                    <div className='space-y-1 px-2 pb-3 pt-2'>
                        {navigation.map((item) => (
                            <Link key={item.name} to={item.refto}>
                                <Disclosure.Button 
                                    key={item.name + '_2'} 
                                    className={classNames(
                                    item.current ? 'bg-gray-600 text-white nav-menu-text' : 'text-gray-300 hover:bg-gray-700 hover:text-white nav-menu-text',
                                    'block rounded-md px-3 py-2 text-base font-medium'
                                    )}
                                    aria-current={item.current ? 'page' : undefined}
                                    // onClick={() => setActiveMenuItem(item)}
                                >
                                    {item.name}
                                </Disclosure.Button>
                            </Link>
                        ))}
                        <div className='px-2'>
                            <button onClick={() => handleLanguageChange('sk')} className='btn btn-sm'>SK<img src={sk} alt='SK'/></button>
                            <button onClick={() => handleLanguageChange('en')} className='btn btn-sm'>EN<img src={en} alt='EN'/></button>
                            <button onClick={() => handleLanguageChange('de')} className='btn btn-sm'>DE<img src={de} alt='DE'/></button>
                        </div>
                    </div>
                </Disclosure.Panel>
                </>
            )}
            </Disclosure>
            <div className='h-16'></div>
        </>
    )
}