import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'react-bootstrap';

/* Local imports */
import '../assets/styles/products.css';

import laser from '../assets/images/laser/2020_02_25_13_47_56.jpg';
import bending from '../assets/images/bending/kobra22.jpg';
import rounding from '../assets/images/rounding/2020_02_25_13_51_15.jpg';
import welding from '../assets/images/welding/2020_02_25_13_55_282.jpg';
import sandblasting from '../assets/images/sandblasting/2020_02_25_13_56_26.jpg';
import gallery from '../assets/images/products/75e1bd0cc5f6.jpg';

const imgs = [laser, bending, rounding, welding, sandblasting];

export default function Products() {
    const { t } = useTranslation();
    
    const [nextBackgroundImg, setNextBackgroundImg] = useState(laser);

    useEffect(() => {
        var indexImg = 1;
        const changeBackgroundImg = () => {
            if (indexImg === imgs.length - 1) indexImg = 0;
            setNextBackgroundImg(imgs[indexImg++]);
        };

        const intervalId = setInterval(changeBackgroundImg, 10000);
        
        return () => {
            clearInterval(intervalId);
        };
    }, []);


    return (
        <div className='products-bg' style={{ backgroundImage: `url(${nextBackgroundImg})`}}>
            <div className='products-bg-content'>
                <h2 className='text-center products-h2'>{t('products')}</h2>
                <div className='products-content'>
                    <Row>
                        <Col xs={6} sm={6} md={4} className='prod-info'>
                            <Link to={'../laser'} style={{textDecoration: 'none'}}>
                                <div className='prod-container parent'>
                                    <img className='max-h-50 prod-infoImg child' src={laser} alt='Laser'/>
                                </div>
                                <h2 className='text-center text-white prod-infoText'>{t('lasering')}</h2>
                            </Link>
                        </Col>
                        <Col xs={6} sm={6} md={4} className='prod-info'>
                            <Link to={'../bending'} style={{textDecoration: 'none'}}>
                                <div className='prod-container parent'>
                                    <img className='max-h-50 prod-infoImg child' src={bending} alt='Bending'/>
                                </div>
                                <h2 className='text-center text-white prod-infoText'>{t('bending')}</h2>
                            </Link>
                        </Col>
                        <Col xs={6} sm={6} md={4} className='prod-info'>
                            <Link to={'../rounding'} style={{textDecoration: 'none'}}>
                                <div className='prod-container parent'>
                                    <img className='max-h-50 prod-infoImg child' src={rounding} alt='Rounding'/>
                                </div>
                                <h2 className='text-center text-white prod-infoText'>{t('rounding')}</h2>
                            </Link>
                        </Col>
                        <Col xs={6} sm={6} md={4} className='prod-info'>
                            <Link to={'../welding'} style={{textDecoration: 'none'}}>
                                <div className='prod-container parent'>
                                    <img className='max-h-50 prod-infoImg child' src={welding} alt='Welding'/>
                                </div>
                                <h2 className='text-center text-white prod-infoText'>{t('welding')}</h2>
                            </Link>
                        </Col>
                        <Col xs={6} sm={6} md={4} className='prod-info'>
                            <Link to={'../sandblasting'} style={{textDecoration: 'none'}}>
                                <div className='prod-container parent'>
                                    <img className='max-h-50 prod-infoImg child' src={sandblasting} alt='Sandblasting'/>
                                </div>
                                <h2 className='text-center text-white prod-infoText'>{t('sandblasting')}</h2>
                            </Link>
                        </Col>
                        <Col xs={6} sm={6} md={4} className='prod-info'>
                            <Link to={'../gallery'}  style={{textDecoration: 'none'}}>
                                <div className='prod-container parent'>
                                    <img className='max-h-50 prod-infoImg child' src={gallery} alt='Constructions'/>
                                </div>
                                <h2 className='text-center text-white prod-infoText'>{t('gallery')}</h2>
                            </Link>
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    );
}