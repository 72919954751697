import React, {useState, useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import Lightbox from 'yet-another-react-lightbox';
import { Zoom  } from 'yet-another-react-lightbox/plugins';

/* Local imports */
import '../../assets/styles/products-photos.css';

import b1 from '../../assets/images/bending/2020_02_25_13_45_11.jpg';
import b2 from '../../assets/images/bending/2020_02_25_13_45_19.jpg';
import b3 from '../../assets/images/bending/2020_03_06_10_50_28_477419.jpg';
import b4 from '../../assets/images/bending/2020_03_06_10_58_29_686645.jpg';
import b5 from '../../assets/images/bending/2020_03_06_10_58_30_264313.jpg';
import b8 from '../../assets/images/bending/2020_03_06_10_58_32_317398.jpg';
import b9 from '../../assets/images/bending/2020_03_06_10_58_32_922914.jpg';
import b10 from '../../assets/images/bending/2020_03_06_10_58_33_551197.jpg';
import b11 from '../../assets/images/bending/2020_03_06_10_58_34_259245.jpg';
import b12 from '../../assets/images/bending/2020_03_06_10_58_35_272635.jpg';
import kobra1 from '../../assets/images/bending/kobra1.jpg';
import kobra2 from '../../assets/images/bending/kobra2.jpg';

export default function Bending() {
    const { t } = useTranslation();

    const images = [b1,b2,b3,b4,b5,b8,b9,b10,b11,b12, kobra1, kobra2];

    const [open, setOpen] = useState(false);
    const [imgBox, setImgBox] = useState([]);

    function actualImages(i){
        const tmp  = [];
        for (let j = i; j < images.length; j++) {
            tmp.push({src: images[j]});
        }
        for (let k = 0; k < i; k++) {
            tmp.push({src: images[k]});
            
        }
        setImgBox(tmp);
    }

    useEffect(() => {
        if (imgBox.length > 0) setOpen(true);
    }, [imgBox]);

    return (
        <>
            <div className='gal-bg' style={{backgroundImage: `url(${images[Math.floor(Math.random() * images.length)]})`}}>
                <h2 className='text-center gal-product-item'>{t('bending')}</h2>
                <div className='gal-gallery'>
                {images.map((image, index) => (
                    <div className='gal-gallery-item' key={index}>
                    <img src={image} alt={`${index + 1}`} onClick={() => actualImages(index)}/>
                    </div>
                ))}
                </div>
            </div>

            <Lightbox
                open={open}
                close={() => setOpen(false)}
                slides={imgBox}
                plugins={[Zoom]}
            />
        </>
    )
}