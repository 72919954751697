import React, {useState, useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import Lightbox from 'yet-another-react-lightbox';
import { Zoom  } from 'yet-another-react-lightbox/plugins';

/* Local imports */
import '../../assets/styles/products-photos.css';

import l1 from '../../assets/images/laser/2020_02_25_13_47_56.jpg';
import l2 from '../../assets/images/laser/2020_02_25_13_48_27.jpg';
import l3 from '../../assets/images/laser/2020_02_25_13_49_35.jpg';
import l4 from '../../assets/images/laser/2020_03_06_11_00_45_081683.jpg';
import l5 from '../../assets/images/laser/2020_03_06_11_00_51_239974.jpg';
import l6 from '../../assets/images/laser/2020_03_06_11_00_56_302739.jpg';

export default function Laser() {
    const { t } = useTranslation();

    const images = [l1,l2,l3,l4,l5,l6];

    const [open, setOpen] = useState(false);
    const [imgBox, setImgBox] = useState([]);

    function actualImages(i){
        const tmp  = [];
        for (let j = i; j < images.length; j++) {
            tmp.push({src: images[j]});
        }
        for (let k = 0; k < i; k++) {
            tmp.push({src: images[k]});
            
        }
        setImgBox(tmp);
    }

    useEffect(() => {
        if (imgBox.length > 0) setOpen(true);
    }, [imgBox]);

    return (
        <>
            <div className='gal-bg' style={{backgroundImage: `url(${images[Math.floor(Math.random() * images.length)]})`}}>
                <h2 className='text-center gal-product-item'>{t('lasering')}</h2>
                <div className='gal-gallery'>
                {images.map((image, index) => (
                    <div className='gal-gallery-item' key={index}>
                        <img src={image} alt={`${index + 1}`} onClick={() => actualImages(index)}/>
                    </div>
                ))}
                </div>
            </div>

            <Lightbox
                open={open}
                close={() => setOpen(false)}
                slides={imgBox}
                plugins={[Zoom]}
            />
        </>
    )
}