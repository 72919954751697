import React from 'react';
import {useTranslation} from 'react-i18next';
import { Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

/* Local imports */
import '../assets/styles/footer.css';

export default function Footer() {
    
    const { t } = useTranslation();

    return (
        <div className='bg-dark' style={{padding: '20px 0'}}>
            <Container className='text-light' style={{zIndex: '10'}}>
                <Row>
                    <Col xs={1} sm={1} md={1} lg={1}></Col>
                    <Col xs={11} sm={11} md={4} lg={4} style={{padding:'0'}}>
                        <Row>
                            <Col>
                                <strong>{t('services')}</strong>
                            </Col>
                            <Col>
                                <Link to='/laser' className='services-links'>{t('lasering')}</Link>
                                <br/>
                                <Link to='/bending' className='services-links'>{t('bending')}</Link>
                                <br/>
                                <Link to='/rounding' className='services-links'>{t('rounding')}</Link>
                            </Col>
                            <Col>
                                <Link to='/welding' className='services-links'>{t('welding')}</Link>
                                <br/>
                                <Link to='/sandblasting' className='services-links'>{t('sandblasting')}</Link>
                                <br/>
                                <Link to='/gallery' className='services-links'>{t('gallery')}</Link>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={1} sm={1} md={1} lg={1}></Col>
                    <Col xs={5} sm={5} md={2} lg={2} style={{padding:'0 1%'}}>
                        <Row>
                            <Col>
                                <strong>{t('contact_inquiry')}:</strong><br/>
                                +421 948 560 613 <br/>
                                info@vatmann.sk
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={6} sm={6} md={4} lg={4} style={{padding:'0 5%'}}>
                        <Row>
                            <Col>
                                <strong>{t('contact_construction')}:</strong><br/>
                                +421 901 735 738 <br/>
                                konstrukcia@vatmanntechnology.sk
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <div style={{textAlign:'center', paddingTop:'1em'}}>{t('copyright')}</div>
                </Row>
            </Container>
        </div>
    )
}