import React, { useEffect } from 'react';
import { HashRouter, Routes, Route } from 'react-router-dom';
import {useTranslation} from 'react-i18next';

/* Local imports */
import '../src/assets/styles/app.css';
import Home from './pages/Home';
// import About from './pages/About';
import Layout from './pages/Layout';

import Products from './pages/Products';
import Laser from './pages/products/Laser';
import Bending from './pages/products/Bending';
import Rounding from './pages/products/Rounding';
import Welding from './pages/products/Welding';
import Sandblasting from './pages/products/Sandblasting';

import Design from './pages/Design';
import Contact from './pages/Contact';
import Gallery from './pages/Gallery';

export default function App() {
  const { i18n  } = useTranslation();

  useEffect(() => {
    const lng = 'sk';
    i18n.changeLanguage(lng);
  }, [i18n])

  return (
    <HashRouter>
      <Routes>
        <Route path='/' element={<Layout />}>
          <Route index element={<Home />} />
          {/* <Route path='about' element={<About />} /> */}
          <Route path='products' element={<Products />} />
          <Route path='laser' element={<Laser />} />
          <Route path='bending' element={<Bending />} />
          <Route path='rounding' element={<Rounding />} />
          <Route path='welding' element={<Welding />} />
          <Route path='sandblasting' element={<Sandblasting />} />
          <Route path='gallery' element={<Gallery />} />
          <Route path='design' element={<Design />} />
          <Route path='contact' element={<Contact />} />
          <Route path='*' element={<Home />} />
        </Route>
      </Routes>
    </HashRouter>
  );
}