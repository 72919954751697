import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import { useTranslation } from 'react-i18next';

/* Local imports */
import vatman_bg from '../assets/images/contact/vatmann foto.jpg'
import '../assets/styles/contact.css';

export default function Contact() {
    const { t } = useTranslation();

    return (
        <>
            <div id='contactID' className='relative isolate overflow-hidden bg-blue-300 contact-container'>
                <Container className='py-10 sm:py-5'>
                    <img src={vatman_bg} alt='contactbg' className='absolute lg:scale-150 md:scale-125 scale-100 inset-0 -z-10 h-full w-full object-cover object-bottom md:object-bottom lg:object-bottom'/>
                    <Row className='contact-bg-block'>
                        <Col xs={4} md={3} className='contactBlocks'> 
                            <h5 style={{textAlign:'center'}}>{t('contact')}</h5>
                            <h5 style={{color: '#5fb5e7'}}>{t('adress')}:</h5>
                            <p>
                            VATMANN, spol. s r. o.<br/>
                            SNP 1, 033 01 LIPTOVSKÝ HRÁDOK<br/>
                            </p>
                            <h5 style={{color: '#5fb5e7'}}>{t('contact_info')}:</h5>
                            <p>
                            {t('inquiry')}:<br/>
                            +421 948 560 613, info@vatmann.sk<br/>

                            {t('construction')}:<br/>
                            +421 901 735 738, konstrukcia@vatmanntechnology.sk<br/>
                            </p>
                            <h5 style={{color: '#5fb5e7'}}>{t('billing_data')}:</h5>
                            <p>
                            VATMANN, spol. s r. o.<br/>
                            SNP 1, 033 01 LIPTOVSKÝ HRÁDOK<br/>

                            IČO: 31 735 738<br/>
                            DIČ: 2020524627<br/>
                            IČ DPH: SK2020524627<br/>
                            </p>
                        </Col>
                    </Row>
                    <Row className='contact-bg-block'>
                        <Col xs={4} md={3} className='contactBlocks'>
                            <h5 style={{textAlign:'center'}}>{t('find_us')}</h5>
                            <iframe title='vatmann' width='100%' height='80%' className='w-full' src='https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d10462.22916407475!2d19.711759!3d49.0380265!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x2191f86d87500b55!2sVATMANN%20s.r.o!5e0!3m2!1ssk!2ssk!4v1582116402788!5m2!1ssk!2ssk'></iframe>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}