import React, {useState, useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import Lightbox from 'yet-another-react-lightbox';
import { Zoom  } from 'yet-another-react-lightbox/plugins';

/* Local imports */
import '../assets/styles/gallery.css';

export default function Gallery() {
    const { t } = useTranslation();

    //Get images from folder
    const imagePathContext = require.context('../assets/images/gallery/', false, /\.(png|jpe?g|jpg|svg)$/);
    const imageFileNames = imagePathContext.keys();
    
    const images = imageFileNames.map((fileName) => {
        const image = imagePathContext(fileName);
        return image;
    });

    //Load images into array
    const imagesLoad = [];
    for (let i = 0; i < images.length; i++) {
        imagesLoad.push(images[i]);
    }

    const [open, setOpen] = useState(false);
    const [imgBox, setImgBox] = useState([]);

    function actualImages(i){
        const tmp  = [];
        for (let j = i; j < imagesLoad.length; j++) {
            tmp.push({src: imagesLoad[j]});
        }
        for (let k = 0; k < i; k++) {
            tmp.push({src: imagesLoad[k]});
            
        }
        setImgBox(tmp);
    }

    useEffect(() => {
        if (imgBox.length > 0) setOpen(true);
    }, [imgBox]);

    return (
        <>
            <div className='gal-bg' style={{backgroundImage: `url(${imagesLoad[Math.floor(Math.random() * imagesLoad.length)]})`}}>
            <h2 className='text-center gal-product-item'>{t('gallery')}</h2>
                <div className='gal-gallery'>
                {imagesLoad.map((image, index) => (
                    <div className='gal-gallery-item' key={index}>
                    {/* <img src={image} alt={`${index + 1}`} onClick={() => setImgBox([{src: image}])}/> */}
                    <img src={image} alt={`${index + 1}`} onClick={() => actualImages(index)}/>
                    </div>
                ))}
                </div>
            </div>
            
            <Lightbox
                open={open}
                close={() => setOpen(false)}
                slides={imgBox}
                plugins={[Zoom]}
            />
        </>
    )
}