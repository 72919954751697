import React, {useState, useEffect} from 'react';
//import Image from 'react-bootstrap/Image';
import Lightbox from 'yet-another-react-lightbox';
import 'yet-another-react-lightbox/styles.css';
import 'yet-another-react-lightbox/plugins/captions.css';
import { Zoom, Download  } from 'yet-another-react-lightbox/plugins';
import { useTranslation } from 'react-i18next';

/* Local imports */
import '../assets/styles/design.css';

import des1 from '../assets/images/design/gtb_new.jpg';
import des2 from '../assets/images/design/design1.png';
import lf1 from '../assets/images/design/leták vyroba 2023.jpg';
import lf2 from '../assets/images/design/leták fasada 1.jpg';
import lf3 from '../assets/images/design/letak corten.jpg';

import liphr from '../assets/images/design/liptovsky_hradok.png';
import heart from '../assets/images/design/srdce1.jpeg';
import facade from '../assets/images/design/click1.jpg';
import visual from '../assets/images/design/mara.jpg';

export default function Design() {
    const { t } = useTranslation();
    
    const images1 = [liphr, visual, facade, heart];
    const images2 = [lf1, lf2, lf3];
    
    const [activeImageIndex, setActiveImageIndex] = useState(0);
    const [selectedImage] = useState(null);
    
    const [open, setOpen] = useState(false);
    const [broc, setBroc] = useState([]);
    
    useEffect(() => {
        // Function to handle the image rotation
        const rotateImages = setInterval(() => {
            setActiveImageIndex((prevIndex) => (prevIndex + 1) % images1.length);
        }, 5000);
        
        // Clear the interval when the component is unmounted
        return () => clearInterval(rotateImages);
    }, [images1.length]);

    useEffect(() => {
        if (broc.length > 0) setOpen(true);
    }, [broc]);
    

    function setBrocToOpen(brocIndex) {
        setBroc([{src: images2[brocIndex]}]);
    }

    return (
        <>
        <div className='design-container design1-bg'>
            <div className='design-top-bg design-big-grid' style={{backgroundImage: `url(${images1[activeImageIndex]})`}}>
                <div className='design-overlay'>
                    <h1 className='design-h1'>{t('functionality_cap')}<br/>{t('minimalism_cap')}<br/>{t('estetic_cap')}</h1>
                </div>
            </div>
        </div>

        {/* <div style={{position:'relative'}}>
            <div className='design-bottom-bg'>

            </div>
        </div> */}
        <div style={{position:'relative'}}>
            <div className='design_mara_1 design_mara_all'></div>
            <div className='design_mara_2 design_mara_all'></div>
            <div className='design_vodamara_1 design_mara_all'></div>
            <div className='design_vodamara_2 design_mara_all'></div>
        </div>

        <div className='design-container design-bottom-bg2' style={{height:'45em'}}>
            <div className='design-content4'>
                <img src={des2} alt='des2' style={{cursor:'pointer'}} onClick={() => setBroc([{src: des2},{src: des1}])}/>
                <img src={des1} alt='des1' style={{cursor:'pointer'}} onClick={() => setBroc([{src: des1},{src: des2}])}/>
            </div>
        </div>

        <div className='bg-gray-700 design-parent-container' style={{height:'20em'}}>
            <div className='design-left-grid' style={{display:'inline-block'}}>
                <h3 style={{color: 'white', textAlign:'center', paddingTop:'1em'}}>{t('samples_products')}</h3>
                <div className='design2-brochures-photos'>
                    {images2.map((image, index) => (
                        <div
                        key={index}
                        // className={`design-gallery-image flex-child ${selectedImage === image ? 'selected' : ''}`}
                        className={`design-gallery-image ${selectedImage === image ? 'selected' : ''} design4-image-container`}
                        onClick={() => setBrocToOpen(index)}
                        >
                            <span className='design4-arrow'>▼</span>
                            <img src={image} alt={`${index}`} className='design4-image'/>
                            <span className='design4-icon'>+</span>
                        </div>
                    ))}
                </div>
            </div>
                <a href='https://www.fortesystem.sk/sk' className='design-right-grid'>
                    <div className='design-link'>
                        <h1 className='design-h1'>{t('linkon')} Forte System <br/>E-shop</h1>
                    </div>
                </a>
        </div>

        <Lightbox
            open={open}
            close={() => setOpen(false)}
            slides={broc}
            plugins={[Zoom, Download]}
        />

        </>
    );
}