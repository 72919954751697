import React, { useState, useEffect } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { Col, Image, Row } from 'react-bootstrap';
import Lightbox from 'yet-another-react-lightbox';

/* Local imports */
import '../assets/styles/home.css';
import front from '../assets/images/home/front.png';
import back from '../assets/images/home/back.jpg';
import uvod1 from '../assets/images/home/uvod1.jpg';
import uvod2 from '../assets/images/home/uvod2.jpg';

import '../assets/styles/about.css';
import cert_1_sk from '../assets/images/certificates/9001_sk.png';
import cert_1_en from '../assets/images/certificates/9001_en.png';
import cert_1_de from '../assets/images/certificates/9001_de.png';
import cert_2_sk1 from '../assets/images/certificates/AD 2000_SK-1.png';
import cert_2_sk2 from '../assets/images/certificates/AD 2000_SK-2.png';
import cert_2_en1 from '../assets/images/certificates/AD 2000_EN-1.png';
import cert_2_en2 from '../assets/images/certificates/AD 2000_EN-2.png';
import cert_2_de1 from '../assets/images/certificates/AD 2000_DE-1.png';
import cert_2_de2 from '../assets/images/certificates/AD 2000_DE-2.png';
import cert_3_1 from '../assets/images/certificates/STN EN ISO 3834_2-3.png';
import cert_3_2 from '../assets/images/certificates/STN EN ISO 3834_2-4.png';
import cert_3_3 from '../assets/images/certificates/STN EN ISO 3834_2-1.png';
import cert_3_4 from '../assets/images/certificates/STN EN ISO 3834_2-2.png';
import zhoda_sk_01 from '../assets/images/certificates/zhoda_sk_01.png';
import zhoda_sk_02 from '../assets/images/certificates/zhoda_sk_02.png';
import zhoda_en_01 from '../assets/images/certificates/zhoda_en_01.png';
import zhoda_en_02 from '../assets/images/certificates/zhoda_en_02.png';
import zhoda_de_01 from '../assets/images/certificates/zhoda_de_01.png';
import zhoda_de_02 from '../assets/images/certificates/zhoda_de_02.png';
import zvar_sk_01 from '../assets/images/certificates/zvar_sk_01.png';
import zvar_sk_02 from '../assets/images/certificates/zvar_sk_02.png';
import zvar_en_01 from '../assets/images/certificates/zvar_en_01.png';
import zvar_en_02 from '../assets/images/certificates/zvar_en_02.png';
import zvar_de_01 from '../assets/images/certificates/zvar_de_01.png';
import zvar_de_02 from '../assets/images/certificates/zvar_de_02.png';

export default function Home() {
    const { t } = useTranslation();

    const [index, setIndex] = useState(0);

    const handleSelect = (selectedIndex) => {
        setIndex(selectedIndex);
    };

    const certificates = [
        [cert_1_sk, cert_1_en, cert_1_de],
        [cert_2_sk1, cert_2_sk2, cert_2_en1, cert_2_en2, cert_2_de1, cert_2_de2],
        [cert_3_1, cert_3_2, cert_3_3, cert_3_4],
        [zhoda_sk_01, zhoda_sk_02, zhoda_en_01, zhoda_en_02, zhoda_de_01, zhoda_de_02],
        [zvar_sk_01, zvar_sk_02, zvar_en_01, zvar_en_02, zvar_de_01, zvar_de_02]
    ];

    const [open, setOpen] = useState(false);
    const [certs, setCerts] = useState([]);
    
    const getCert = () => {
        const lang = i18next.language === 'sk' ? 0 : 
                        i18next.language === 'de' ? 2 : 1;
        const lang_fix_to_en = lang === 2 ? 1 : lang;

        function setCertToOpen(certIndex) {
            if(certIndex === 0) setCerts(
                [{src: certificates[certIndex][lang]}]);
            else if(certIndex === 1) setCerts([
                {src: certificates[certIndex][lang * 2]},
                {src: certificates[certIndex][lang * 2 + 1]}]);
            else if(certIndex === 2) setCerts([
                {src: certificates[certIndex][lang_fix_to_en * 2]},
                {src: certificates[certIndex][lang_fix_to_en * 2 + 1]}]);
            else if(certIndex === 3) setCerts([
                {src: certificates[certIndex][lang * 2]},
                {src: certificates[certIndex][lang * 2 + 1]}]);
            else if(certIndex === 4) setCerts([
                {src: certificates[certIndex][lang * 2]},
                {src: certificates[certIndex][lang * 2 + 1]}]);
        }
    
        return (
            <>
            <Row style={{padding:'2em 0'}}>
                <Col xs={4}>
                    <Image src={certificates[0][lang]} onClick={() => setCertToOpen(0)} style={{marginLeft:'auto', marginRight:'0'}} className='about-cert-img'/>
                </Col>
                <Col xs={4}>
                    <Image src={certificates[1][lang * 2]} onClick={() => setCertToOpen(1)} style={{margin:'auto'}} className='about-cert-img'/>
                </Col>
                <Col xs={4}>
                    <Image src={certificates[2][lang_fix_to_en * 2]} onClick={() => setCertToOpen(2)} style={{marginLeft:'0', marginRight:'auto'}} className='about-cert-img'/>
                </Col>
            </Row>
            <Row style={{padding:'2em 0'}}>
                <Col xs={5}>
                    <Image src={certificates[3][lang * 2]} onClick={() => setCertToOpen(3)} style={{marginLeft:'auto', marginRight:'0'}} className='about-cert-img'/>
                </Col>
                <Col xs={2}></Col>
                <Col xs={5}>
                    <Image src={certificates[4][lang * 2]} onClick={() => setCertToOpen(4)} style={{marginLeft:'0', marginRight:'0'}} className='about-cert-img'/>
                </Col>
            </Row>
            </>
        )
    };

    useEffect(() => {
        if (certs.length > 0) setOpen(true);
    }, [certs]);

    return (
        <>
        <Carousel activeIndex={index} onSelect={handleSelect} className='home-container'>
            <Carousel.Item>
                <img
                className='d-block object-cover w-100 home-container-item'
                src={back}
                alt='Firma1'
                />
                <Carousel.Caption className='captBlock'>
                    <div className='captText scaled-text'>
                        <h3>{t('home_1_1')}</h3>
                        <p>{t('home_1_2')}</p>
                    </div>
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
                <img
                className='d-block object-cover w-100 home-container-item'
                src={uvod1}
                alt='Firma3'
                />

                <Carousel.Caption className='captBlock'>
                    <div className='captText scaled-text'>
                        <h3>{t('home_3_1')}</h3>
                        <p>{t('home_3_2')}</p>
                    </div>
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
                <img
                className='d-block object-cover w-100 home-container-item'
                src={uvod2}
                alt='Firma4'
                />

                <Carousel.Caption className='captBlock'>
                    <div className='captText scaled-text'>
                        <h3>{t('home_4_1')}</h3>
                        <p>{t('home_4_2')}</p>
                    </div>
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
                <img
                className='d-block object-cover w-100 home-container-item'
                src={front}
                alt='Firma2'
                />

                <Carousel.Caption className='captBlock'>
                    <div className='captText scaled-text'>
                        <h3>{t('home_2_1')}</h3>
                        <p>{t('home_2_2')}</p>
                    </div>
                </Carousel.Caption>
            </Carousel.Item>
        </Carousel>

        <div className='about-container'>
            <div className='about-background'></div>
            <div className='about-content'>
                <div className='mx-auto max-w-7xl px-6 lg:px-8'>
                    {/* <div className='mx-auto max-w-2xl lg:mx-0'> */}
                    <div className='mx-auto' style={{margin: 'auto', width: '90%'}}>
                        <h2 className='text-4xl font-bold tracking-tight text-white sm:text-6xl'>VATMANN, spol. s r.o.</h2>
                        <div className='mt-6 text-lg leading-8 text-gray-300'>
                                <p>{t('about_1_1')} <strong>{t('about_1_2')}</strong>.<br/>{t('about_1_3')}</p>
                                <p>{t('about_2_1')} <br/> {t('about_2_2')}</p>
                                <p>{t('about_3_1')} <strong>WIG, TIG, MIG, MAG, {t('about_3_2')}</strong> <br/> {t('about_3_3')}.</p>
                        </div>
                    </div>
                </div>
                <div className='relative isolate overflow-hidden'>
                    <br/>
                    {getCert()}
                </div>
            </div>
        </div>

        <Lightbox
            open={open}
            close={() => setOpen(false)}
            slides={certs}
        />
        </>
    )
}