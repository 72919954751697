import React from 'react';
import ReactDOM from 'react-dom/client';

/* Local imports */
import './i18n';

import './input.css';
import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
